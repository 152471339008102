import { Button, Col, Divider, Image, Row, Space, Typography } from "antd";
import React, { useState } from "react";
import "../../Assets/Css/SinglePage.css";
import OrganizationalStructure from "../../Assets/Images/Profile/struktur-organisasi.png";
import Team1Image from "../../Assets/Images/Profile/team-1.png";
import Team2Image from "../../Assets/Images/Profile/team-2.png";
import Team3Image from "../../Assets/Images/Profile/team-3.png";
import Team4Image from "../../Assets/Images/Profile/team-4.png";
import Team5Image from "../../Assets/Images/Profile/team-5.png";
import TeamAll from "../../Assets/Images/Profile/team-all-2024.png";

const { Text } = Typography;

const Team = () => {
  const [path, setPath] = useState("");
  return (
    <>
      <Space className="content-selector">
        <Button className={path === "" && "active"} onClick={() => setPath("")}>
          Dewan Komisaris dan Direktur
        </Button>
        <Button
          className={path === "structure" && "active"}
          onClick={() => setPath("structure")}
        >
          Struktur Organisasi
        </Button>
      </Space>

      {path === "" && (
        <Row
          gutter={[20, 0]}
          justify={"space-between"}
          align={"top"}
          style={{ marginTop: "20px" }}
        >
          <Col span={24} style={{ textAlign: "center", marginBottom: "20px" }}>
            <Image
              src={TeamAll}
              preview={false}
              style={{ scale: "1.05 1.05" }}
            />
          </Col>
          <Col span={24} style={{ textAlign: "left", marginBottom: "20px" }}>
            <Text className="h3" style={{ marginBottom: 0, textAlign: "left" }}>
              Tim Kami
            </Text>
            <Text className="h5">Board of Commisioner and Director</Text>
          </Col>
          <Col span={24} lg={24}>
            <Row gutter={10}>
              <Col span={24} lg={8}>
                <Image
                  src={Team1Image}
                  preview={false}
                  className="team-image"
                />
              </Col>
              <Col span={24} lg={16}>
                <div className="team-group">
                  <Text className="h5" style={{ fontWeight: "600" }}>
                    Steffen Fang
                  </Text>
                  <Text className="h5">Direktur Utama</Text>
                  <p>
                    Direktur Utama Perseroan sejak tahun 2018. Warga Negara
                    Indonesia, lahir pada tahun 1978, berdomisili di
                    Jakarta.Beliau memiliki izin perorangan dari OJK sebagai
                    Wakil Perantara Pedagang Efek (No.
                    KEP-276/PM.212/PJ-WPPE/2021), Wakil Penjamin Emisi Efek (No.
                    KEP-67/PM.212/PJ-WPEE/2022) dan Wakil Manajer Investasi (No.
                    KEP-29/PM.21/PJ-WMI/2022).
                  </p>
                  <p>
                    Beliau menyelesaikan pendidikan pada Fakultas Ekonomi
                    universitas Indonesia S2 (MAGISTER AKUNTANSI) tahun 2004
                    Pernah memegang sejumlah posisi strategis pada beberapa
                    perusahaan, antara lain sebagai Eksekutif Direktur PT
                    Danatama Makmur Sekuritas (2000-2013), sebagai Direktur
                    Keuangan pada PT Graha Lotus Bening (2014 - 2018), sebagai
                    Direktur Utama PT Surya Fajar Capital Tbk (2014 - Januari
                    2018), Dan saat ini menjabat sebagai Direktur Utama PT Surya
                    Fajar Sekuritas (Februari 2018 – sekarang).
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Divider />

          <Col span={24} lg={24}>
            <Row gutter={10}>
              <Col span={24} lg={8}>
                <Image
                  src={Team2Image}
                  preview={false}
                  className="team-image"
                />
              </Col>
              <Col span={24} lg={16}>
                <div className="team-group">
                  <Text className="h5" style={{ fontWeight: "600" }}>
                    Hevy Yafanny (Fanny)
                  </Text>
                  <Text className="h5">Direktur</Text>
                  <p>
                    Direktur Perseroan sejak tahun 2018. Warga Negara Indonesia,
                    lahir pada tahun 1976, berdomisili di Tangerang. Beliau
                    memiliki izin perorangan dari OJK sebagai Wakil Perantara
                    Pedagang Efek (No. KEP-44/PM.212/PJ-WPPE/2022). Beliau
                    menyelesaikan pendidikan pada Fakultas Ekonomi STIE YKPN
                    Sarjana Akuntansi tahun 1997 berpengalaman lebih dari 20
                    tahun dengan spesialis finance, tax dan accounting.
                    Mengawali karirnya di Astra International pada 1997, dan
                    selanjutnya bergabung dengan Deloitte Touche Tomatsu (Hans
                    Tuanakotta & Mustofa), sebagai auditor.
                  </p>
                  <p>
                    Pada tahun 2002, beliau bergabung dengan Masindo Grup,
                    memimpin finance dan accounting departemen pada perusahaan
                    multi finance & coal mining di Grup Masindo. Selanjutnya
                    beliau berkarir di Danatama Makmur Sekuritas memimpin team
                    accounting service pada divisi investment banking, dan
                    selanjutnya sebagai Co-Founder dari SF Capital yang
                    memberikan jasa advisory dan accounting service pada
                    berbagai sektor industri. Dan saat ini menjabat sebagai
                    Direktur PT Surya Fajar Sekuritas (Februari 2018 –
                    sekarang).
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Divider />

          <Col span={24} lg={24}>
            <Row gutter={10}>
              <Col span={24} lg={8}>
                <Image
                  src={Team4Image}
                  preview={false}
                  className="team-image"
                />
              </Col>
              <Col span={24} lg={16}>
                <div className="team-group">
                  <Text className="h5" style={{ fontWeight: "600" }}>
                    Rustan Tober FOH
                  </Text>
                  <Text className="h5">Direktur</Text>
                  <p>
                    Warga Negara Indonesia, berdomisili di Jakarta. Beliau
                    menyelesaikan pendidikannya di Unika Atma Jaya Program
                    Magister Manajemen. Mengawali karir di PT Sinarmas Sekuritas
                    sebagai Floor Trader, jabatan terakhir adalah Corporate
                    Finance Manager. Kemudian melanjutkan karir di PT Aldiracita
                    Sekuritas Indonesia. Saat ini beliau menjabat sebagai
                    Direktur PT Surya Fajar Sekuritas.
                  </p>
                  <p>
                    Beliau sudah memiliki izin perorangan dari Otoritas Jasa
                    Keuangan (OJK) sebagai Wakil Perantara Pedagang Efek (WPPE)
                    dan Wakil Penjamin Emisi Efek (WPEE).
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Divider />

          <Col span={24} lg={24}>
            <Row gutter={10}>
              <Col span={24} lg={8}>
                <Image
                  src={Team3Image}
                  preview={false}
                  className="team-image"
                />
              </Col>
              <Col span={24} lg={16}>
                <div className="team-group">
                  <Text className="h5" style={{ fontWeight: "600" }}>
                    Drs. Mohammad Raylan, MM
                  </Text>
                  <Text className="h5">Komisaris Independen</Text>
                  <p>
                    Warga Negara Indonesia, Lahir di Medan pada tahun 1957, saat
                    ini berdomisili di Jakarta Selatan. Memperoleh Gelar Sarjana
                    dari Universitas Krisnadwipayana jurusan Ekonomi Manajemen
                    Tahun 1985, serta gelar Magister Manajemen dari Universitas
                    Satyagama Tahun 2006. Berpengalaman lebih dari 38 tahun
                    sebagai pengawas di pasar modal. Memulai karir di Otoritas
                    Jasa Keuangan (OJK) sebagai Pelaksana pada Bagian Umum,
                    Sekretariat, Badan Pelaksana Pasar Modal Tahun 1978, serta
                    pernah menjabat sebagai Kepala Bagian Pemeriksaan dan
                    Penyidikan Emiten dan Perusahaan Publik Sektor Riil, Biro
                    Pemeriksaan dan Penyidikan Badan Pengawas Pasar Modal dan
                    Lembaga Keuangan Tahun 2006 serta sebagai Deputi Direktur
                    Pemantauan Perusahaan Sektor Jasa Direktorat Penilaian
                    Keuangan Perusahaan Sektor Jasa Departemen Pengawasan Pasar
                    Modal 2B Otoritas Jasa Keuangan (OJK) Tahun 2014, dan
                    sebagai Direktur Penilaian Keuangan Perusahaan Sektor Jasa,
                    Departemen Pengawasan Pasar Modal 2B Otoritas Jasa Keuangan
                    (OJK) Tahun 2016.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>

          <Divider />

          <Col span={24} lg={24}>
            <Row gutter={10}>
              <Col span={24} lg={8}>
                <Image
                  src={Team5Image}
                  preview={false}
                  className="team-image"
                />
              </Col>
              <Col span={24} lg={16}>
                <div className="team-group">
                  <Text className="h5" style={{ fontWeight: "600" }}>
                    Wahyu Sudaryanto
                  </Text>
                  <Text className="h5">Komisaris</Text>
                  <p>
                    Warga negara Indonesia, lahir di Yogyakarta pada tahun 1969,
                    memperoleh gelar Sarjana Ekonomi dari STIE YKPN serta Master
                    Akuntansi dari Universitas Triksakti. Mengawali karir
                    sebagai Auditor di Kantor Akuntan Publik dan masuk ke pasar
                    modal sejak tahun 1996.
                  </p>
                  <p>
                    Beliau pernah menduduki jajaran Manajerial di berbagai
                    Perusahaan Efek, salah satunya adalah sebagai Direktur
                    Operasional di PT Trust Sekuritas, sekarang ini beliau
                    menjabat sebagai Komisaris dari PT Surya Fajar Sekuritas.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {path === "structure" && (
        <Row
          gutter={[20, 0]}
          justify={"space-between"}
          align={"top"}
          style={{ marginTop: "20px", marginBottom: "40px" }}
        >
          <Col span={24} style={{ textAlign: "center", overflowX: "auto" }}>
            <Image
              src={OrganizationalStructure}
              preview={false}
              style={{ minWidth: "800px" }}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default Team;
